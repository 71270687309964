<template>
  <div>
    <div>
      <div class="head" >
        <div style="float: left;display: inline;padding-left: 20px;">
          <h2>姐弟餐饮管理有限公司</h2>
        </div>
        <div style="float:right;display: inline;padding-right: 30px;">
          <img src="./assets/h.png" style=" height: 80px;">
        </div>
      </div>
      <div class="showFile">
        <img src="./assets/1.png" style=" width: 80%; height: 100%;">
      </div>
      <div class="showFile">
        <img src="./assets/3.png"  style=" width: 80%; height: 100%;">
      </div>
      <div class="showFile">
        <img src="./assets/5.jpg" style=" width: 80%; height: 100%;">
      </div>
      <div style="height: 20px"/>

      <div class="foot">
        <p>Copyright © 2021 姐弟餐饮管理有限公司 All Rights Reserved
          <a href="https://beian.miit.gov.cn" target="_blank">豫ICP备20020406号-3</a>
          </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.head {
  position: fixed;
  width: 100%;
  height: 100px;
  top: 0px;
  z-index: 9999;
  border-top: 1px solid ; border-bottom: 1px solid ;
  background-color: white;
}
.foot{
  position: fixed;
  width: 100%;
  height: 40px;
  margin-top: 50px;
  bottom: 0px;
  z-index: 9999;
  border-top: 1px solid ;
  background-color: white;
}
.showFile{
  width: 100%;
  height: 30%;
  padding-top: 60px;
  padding-bottom: 20px;
}

</style>
